/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-package-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i4 from "@angular/material/toolbar";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "./view-package-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_ViewPackageDialogComponent = [i0.styles];
var RenderType_ViewPackageDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewPackageDialogComponent, data: {} });
export { RenderType_ViewPackageDialogComponent as RenderType_ViewPackageDialogComponent };
function View_ViewPackageDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.track_date); _ck(_v, 1, 0, currVal_0); }); }
function View_ViewPackageDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.track_time); _ck(_v, 1, 0, currVal_0); }); }
function View_ViewPackageDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["signed by: ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.track_signatory); _ck(_v, 1, 0, currVal_0); }); }
function View_ViewPackageDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "progress-step is-complete"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "progress-marker"]], [[1, "data-text", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "progress-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "progress-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" @ ", " on "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPackageDialogComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" by "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPackageDialogComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPackageDialogComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.track_date); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.track_time); _ck(_v, 11, 0, currVal_4); var currVal_5 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.track_signatory) !== " "); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.track_status; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.current_location; _ck(_v, 6, 0, currVal_2); }); }
function View_ViewPackageDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "progress-tracker progress-tracker--vertical progress-tracker--text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPackageDialogComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.job; _ck(_v, 4, 0, currVal_0); }, null); }
function View_ViewPackageDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The package you want to view is not available "]))], null, null); }
export function View_ViewPackageDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i3.View_MatToolbar_0, i3.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i4.MatToolbar, [i1.ElementRef, i5.Platform, i2.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Package Details"])), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "span", [["class", "example-fill-remaining-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-window-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-lg-12 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "card-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPackageDialogComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseTemplate", 2]], null, 0, null, View_ViewPackageDialogComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.job.length; var currVal_3 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ViewPackageDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-package-dialog", [], null, null, null, View_ViewPackageDialogComponent_0, RenderType_ViewPackageDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.ViewPackageDialogComponent, [i7.MAT_DIALOG_DATA, i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewPackageDialogComponentNgFactory = i1.ɵccf("app-view-package-dialog", i6.ViewPackageDialogComponent, View_ViewPackageDialogComponent_Host_0, {}, {}, []);
export { ViewPackageDialogComponentNgFactory as ViewPackageDialogComponentNgFactory };
