import * as i0 from "@angular/core";
var LogService = /** @class */ (function () {
    function LogService() {
    }
    LogService.prototype.log = function (data) {
        // console.log(data);
    };
    LogService.prototype.error = function (data) {
        console.error(data);
    };
    LogService.ngInjectableDef = i0.defineInjectable({ factory: function LogService_Factory() { return new LogService(); }, token: LogService, providedIn: "root" });
    return LogService;
}());
export { LogService };
