<nav class="navbar navbar-top navbar-expand-md navbar-primary" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <!-- <div class="row align-items-center py-4"> -->
    <div class="d-inline-block align-items-center">
      <!-- Title -->
      <div class="d-inline-block">
        <h6 *ngIf="title" class="text-white h2 d-inline-block mb-0">{{title | uppercase}}</h6>
      </div>

      <!-- Breadcrumbs -->
      <div *ngIf="breadcrumbs?.parentBreadcrumb || breadcrumbs?.childBreadcrumb" class="d-inline-block">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          <ol class="breadcrumb breadcrumb-links ">
            <li class="breadcrumb-item text-primary"><a><i class="fas fa-home"></i></a></li>
            <li *ngIf="breadcrumbs?.parentBreadcrumb" class="breadcrumb-item text-primary">
              <a>{{breadcrumbs?.parentBreadcrumb}}</a>
            </li>
            <li *ngIf="breadcrumbs?.childBreadcrumb" class="breadcrumb-item text-primary active" aria-current="page">
              {{breadcrumbs?.childBreadcrumb}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <!-- User -->
    <div class="ml-auto">
      <ul class="navbar-nav align-items-center d-none d-md-flex">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a class="nav-link btn btn-light p-2" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <!-- <span class="avatar avatar-sm rounded-circle">
                <img class="img-responsive" alt="Image placeholder" src="{{profile?.image | img}}">
              </span> -->
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm text-white font-weight-bold">
                  {{profile?.firstname | slice : "0" : "1" | titlecase}}
                  <span>
                    {{profile?.lastname | slice : "0" : "1" | titlecase}}
                  </span>
                </span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/forgot-password']" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Change Password</span>
            </a>
            <div class="dropdown-divider"></div>
            <a (click)="onLogout()" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <!-- </div> -->
  </div>
</nav>
