import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
  name: "img",
})
export class ImgPipe implements PipeTransform {
  private url = environment.apiUrl;

  transform(value: any, args?: any): any {
    return `${this.url}${value}`;
  }
}
