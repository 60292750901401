/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/sidebar/sidebar.component.ngfactory";
import * as i3 from "../../components/sidebar/sidebar.component";
import * as i4 from "@angular/router";
import * as i5 from "../../Auth/auth.service";
import * as i6 from "../../pwa.service";
import * as i7 from "../../shared/helper.service";
import * as i8 from "../../shared/profile.service";
import * as i9 from "../../components/navbar/navbar.component.ngfactory";
import * as i10 from "../../components/navbar/navbar.component";
import * as i11 from "@angular/common";
import * as i12 from "../../components/footer/footer.component.ngfactory";
import * as i13 from "../../components/footer/footer.component";
import * as i14 from "./admin-layout.component";
var styles_AdminLayoutComponent = [i0.styles];
var RenderType_AdminLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLayoutComponent, data: {} });
export { RenderType_AdminLayoutComponent as RenderType_AdminLayoutComponent };
export function View_AdminLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [i4.Router, i5.AuthService, i6.PwaService, i7.HelperService, i8.ProfileService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "main-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-navbar", [], null, null, null, i9.View_NavbarComponent_0, i9.RenderType_NavbarComponent)), i1.ɵdid(4, 114688, null, 0, i10.NavbarComponent, [i6.PwaService, i11.Location, i4.ActivatedRoute, i1.ElementRef, i4.Router, i7.HelperService, i8.ProfileService, i5.AuthService], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_AdminLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-layout", [], null, null, null, View_AdminLayoutComponent_0, RenderType_AdminLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i14.AdminLayoutComponent, [i7.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLayoutComponentNgFactory = i1.ɵccf("app-admin-layout", i14.AdminLayoutComponent, View_AdminLayoutComponent_Host_0, {}, {}, []);
export { AdminLayoutComponentNgFactory as AdminLayoutComponentNgFactory };
