import { Component } from "@angular/core";
import { AuthService } from "./Auth/auth.service";
import { SwUpdate } from "@angular/service-worker";
import { PwaService } from "./pwa.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  iconSet;
  title = "Process Management System";
  constructor(
    private auth: AuthService,
    private swUpdate: SwUpdate,
    private pwa: PwaService
  ) {}

  ngOnInit() {
    this.pwa.initPwaPrompt();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
    this.auth.autoAuthUser();
  }
}
