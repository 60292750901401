import { Component, OnInit, ElementRef } from "@angular/core";
import { SIDAROUTES } from "../sidebar/sidebar.component";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import {
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  ActivatedRoute,
} from "@angular/router";
import { PwaService } from "src/app/pwa.service";
import { filter, map } from "rxjs/operators";
import { ProfileService } from "src/app/shared/profile.service";
import { HelperService } from "src/app/shared/helper.service";
import { AuthService } from "src/app/Auth/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  breadcrumbs: { parentBreadcrumb: string; childBreadcrumb: string };
  title: any;
  profile: any;

  constructor(
    private pwa: PwaService,
    location: Location,
    private activatedRoute: ActivatedRoute,
    private element: ElementRef,
    private router: Router,
    private helpService: HelperService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.location = location;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data["title"];
        let parent = route.parent.snapshot.data["breadCrumb"];
        let child = route.snapshot.data["breadCrumb"];
        this.breadcrumbs = { parentBreadcrumb: "", childBreadcrumb: "" };
        this.title = title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
  }

  ngOnInit() {
    const userId = this.helpService.getUser();
    this.pwa.initPwaPrompt();
    this.profileService.getProfileById(userId);
    this.profileService.getProfileListener().subscribe((data) => {
      this.profile = data;
      // console.log(data);
      // // console.log(this.profile);
    });
    this.listTitles = SIDAROUTES.filter((listTitle) => listTitle);
  }

  installPwa(): void {
    this.pwa.promptEvent.prompt();
  }

  onLogout() {
    this.authService.logout();
  }
}
