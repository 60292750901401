<!--error-404 start-->
<div class="">
  <div class="container my-5">
    <img class="img-100 mx-auto d-block" src="../../assets/img/page404.png" alt="">
    <!-- <div class="error-heading">
      <img src="assets/images/cloud-bg-1.png" class="cloud-first" alt="">
      <img src="assets/images/cloud-bg-2.png" class="cloud-second" alt="">
    </div> -->
    <div class=" col-md-8 mx-auto mt-5">
      <h2 class="text-center">
        <span class="text-danger">
          404
        </span>
        PAGE NOT FOUND!
      </h2>
      <p class="text-center font-weight-bold">The page you are attempting to reach is currently not available. This may
        be because
        the page does not exist or has been moved.
      </p>
    </div>
    <div class="col-12 mt-5">
      <button [routerLink]="'/dashboard'" class="d-block mx-auto btn btn-primary btn-lg">BACK TO HOME PAGE</button>
    </div>
  </div>
</div>
<!--error-404 end-->
