import { Injectable } from "@angular/core";
import { HttpRequest, HttpEventType, HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { shareReplay, map, catchError } from "rxjs/operators";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import {
  FormGroup,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import * as moment from "moment";
import { AuthService } from "../Auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class HelperService {
  public isCollapsed = new Subject<boolean>();
  private banks: any[];
  private banksUpdate = new Subject<any[]>();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getLoginResponse() {
    return JSON.parse(localStorage.getItem("login_response"));
  }

  getAssignedCourier() {
    return JSON.parse(localStorage.getItem("assigned_courier"));
  }

  getShipperID() {
    return JSON.parse(localStorage.getItem("shipper_id"));
  }

  isDateValid(date) {
    // check if date is valid
    return !isNaN(new Date(date).valueOf());
  }

  copyToClipboard(text) {
    // return the observable, so you can notify with the then callback
    // return window.navigator.clipboard.writeText(text);
  }

  getSelection() {
    window.getSelection().toString();
  }

  checkDarkMode() {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  }

  toggleNav() {
    this.isCollapsed.next(true);
  }

  getToggleValue() {
    return this.isCollapsed.asObservable();
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user_id"));
  }

  getUserGroup() {
    return JSON.parse(localStorage.getItem("user_group"));
  }

  getUsername() {
    return JSON.parse(localStorage.getItem("username"));
  }

  getFirstname() {
    return JSON.parse(localStorage.getItem("firstname"));
  }

  getLastname() {
    return JSON.parse(localStorage.getItem("lastname"));
  }

  getPhone() {
    return JSON.parse(localStorage.getItem("phone"));
  }

  getBankCode() {
    return JSON.parse(localStorage.getItem("bank_code"));
  }

  getMomentTime(date) {
    return moment(date).format("lll");
  }

  timeFromNow(time) {
    // return moment(time).fromNow();
  }

  dashboardCalendar(time) {
    // return moment(time).startOf("month").calendar();
  }

  formatDate(date) {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }

  // get bank name by passing user's paystack bank code
  getBank(account_code) {
    let desiredBank;
    // console.log(account_code);
    this.getBanksList();
    desiredBank = this.banks.filter((bank) => bank.code == account_code);
    // console.log(desiredBank);
    return desiredBank[0].name;
  }

  // Get List of Banks from Paystack
  getBanksList() {
    this.http
      .get<{ data: any[] }>(`https://api.paystack.co/bank/`)
      .pipe(
        map((res: { data: any[] }) => res.data),
        shareReplay()
      )
      .subscribe(
        (response: any[]) => {
          this.banks = response;
          this.banksUpdate.next(this.banks);
        },
        (error) => {
          this.banks = [];
          this.banksUpdate.next(this.banks);
        }
      );
  }

  getBankUpdate() {
    return this.banksUpdate.asObservable();
  }

  exportExcel(data, title, tableHeaders) {
    let options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: tableHeaders,
      title: title,
    };
    new AngularCsv(data, title, options);
  }

  lowerCase(string: string) {
    return string.toLowerCase();
  }

  firstUpper(string: string) {
    const username = string.toLowerCase();
    return `${username.charAt(0).toUpperCase()}${username.slice(1)}`;
  }

  upperCase(string: string) {
    return string.toUpperCase();
  }

  longRequest() {
    const request = new HttpRequest(
      "POST",
      "/api/test-request",
      {},
      { reportProgress: true }
    );

    this.http.request(request).subscribe((event) => {
      if (event.type === HttpEventType.DownloadProgress) {
        // console.log("Download progress event", event);
      }

      if (event.type === HttpEventType.UploadProgress) {
        // console.log("Upload progress event", event);
      }

      if (event.type === HttpEventType.Response) {
        // console.log("response received...", event.body);
      }
    });
  }

  merger(array: any[]) {
    // if new Array includes an object that has same name as array, add the counts, else, push
    let result: any[] = [];
    // console.log(result);
    array.forEach((element) => {
      const dontAdd = result.filter(
        (data: any) => element.status === data.status
      );
      // console.log(dontAdd);
      if (dontAdd.length !== 0) {
        const updatedResult = [...result];
        const oldItemIndex = updatedResult.findIndex(
          (c) => c.status === element.status
        );
        updatedResult[oldItemIndex].counts += element.counts;
        result = updatedResult;
      } else {
        result.push(element);
      }
      // console.log(dontAdd);
    });

    // console.log(result);
    return result;
  }

  mergeStatus(array: any[]) {
    // if new Array includes an object that has same name as array, add the counts, else, push
    let result: any[] = [];
    array.forEach((element) => {
      const dontAdd = result.filter(
        (data: any) => element.status === data.status
      );
      if (dontAdd.length !== 0) {
        const updatedResult = [...result];
        const oldItemIndex = updatedResult.findIndex(
          (c) => c.status === element.status
        );
        updatedResult[oldItemIndex].counts += element.counts;
        result = updatedResult;
      } else {
        result.push(element);
      }
      // console.log(dontAdd);
    });

    // console.log(result);
    return result;
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

  validateEmailIsTaken(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    console.log(ctrl);
    this.authService.isEmailTaken(ctrl.value);
    // .pipe(
    //   map((isTaken) => (isTaken ? { isTaken: true } : null)),
    //   catchError(() => null)
    // );
    return this.authService.isUsernameTaken(ctrl.value).pipe(
      map((isTaken) => (isTaken ? { isTaken: true } : null)),
      catchError(() => null)
    );
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    console.log(control);
    return of({ custom: true });
  }

  validateUsernameIsTaken(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.authService.isUsernameTaken(ctrl.value).pipe(
      map((isTaken) => (isTaken ? { isTaken: true } : null)),
      catchError(() => null)
    );
  }
}
