<mat-toolbar>
  <span>Package Details</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  <span><i class="fa fa-window-close" (click)=closeDialog()></i></span>
</mat-toolbar>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 mx-auto">
      <div class="card-wrapper">
        <ng-container *ngIf="points.length; else elseTemplate">
          <div class="container">
            <agm-map [latitude]="currentPos.latitude" [longitude]="currentPos.longitude" [zoom]="zoom"
              [disableDefaultUI]="false" (mapClick)="mapClicked($event)">
              <agm-marker [animation]="currentPos.animate" [latitude]="currentPos.latitude" [iconUrl]="currentPos.icon"
                [longitude]="currentPos.longitude">
              </agm-marker>
              <agm-marker *ngFor="let point of points" [visible]="false" [latitude]="point.latitude"
                [longitude]="point.longitude">
              </agm-marker>
              <agm-marker [latitude]="startPos.latitude" [longitude]="startPos.longitude">
              </agm-marker>
              <agm-polyline strokeColor="grey">
                <agm-polyline-point *ngFor="let point of points" [latitude]="point.latitude"
                  [longitude]="point.longitude">
                </agm-polyline-point>
              </agm-polyline>
            </agm-map>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <p class="text-center">
            The package you're about to track hasn't being dispatched yet.
          </p>
        </ng-template>
        <div class="card-body">
          <h3 class="card-title">Agent Name: {{info.dispatch_agent || 'N/A' }}</h3>
          <h5 class="card-subtitle mb-2 text-muted">Package Details</h5>
          <p class="card-text">Tracking Number: {{ info.ref_no || 'N/A' }}</p>
          <p class="card-text">From: {{ info.shipment_origin || 'N/A' }}</p>
          <p class="card-text">To: {{ info.shipment_destination || 'N/A' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
