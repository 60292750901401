import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { HelperService } from "src/app/shared/helper.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit {
  constructor(private helpService: HelperService) {}

  ngOnInit() {}

  onToggleNav() {
    this.helpService.toggleNav();
  }
}
