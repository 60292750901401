var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { HelperService } from "./helper.service";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ERROR_MESSAGE_DICTIONARY } from "./error-message";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, switchMap, map, } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helper.service";
import * as i3 from "./notification.service";
import * as i4 from "@angular/router";
var SortingService = /** @class */ (function () {
    function SortingService(http, helperService, notification, router) {
        this.http = http;
        this.helperService = helperService;
        this.notification = notification;
        this.router = router;
        this.assignLoading = new Subject();
        this.triggerLoading = new Subject();
        this.excelUploading = new Subject();
        this.sortingListUpdate = new Subject();
        this.apiUrl = environment.apiUrl;
        this.packageUpdate = new Subject();
    }
    SortingService.prototype.getSortingList = function (start_date, end_date, limit, page, courier_id) {
        var _this = this;
        if (start_date === void 0) { start_date = this.helperService.formatDate(new Date()); }
        if (end_date === void 0) { end_date = this.helperService.formatDate(new Date()); }
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        if (courier_id === void 0) { courier_id = 0; }
        var query = "?limit=" + limit + "&page=" + page;
        var data = {
            start_date: start_date,
            end_date: end_date,
            courier_id: courier_id,
        };
        this.http
            .post(this.apiUrl + "app/search/sorting_list/range/" + query, data)
            .subscribe(function (res) {
            // console.log(res);
            _this.sortingList = res;
            _this.sortingListUpdate.next(__assign({}, _this.sortingList));
        }, function (err) {
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.assignJob = function (job) {
        var _this = this;
        // app/job_file/
        this.assignLoading.next(true);
        this.http
            .patch(this.apiUrl + "app/job_file/" + job.id + "/", {
            assigned_agent: job.assigned_agent,
        })
            .subscribe(function (res) {
            _this.assignLoading.next(false);
            // console.log(res);
            _this.router.navigate(["/dashboard"]);
            _this.notification.success("Job Assigned");
            // this.sortingList = res;
            // this.sortingListUpdate.next({ ...this.sortingList });
        }, function (err) {
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.returnJob = function (job) {
        var _this = this;
        // app/job_file/
        var returned_user = this.helperService.getUser();
        job.returned_user = returned_user;
        this.assignLoading.next(true);
        this.http
            .patch(this.apiUrl + "app/job_file/" + job.id + "/", {
            delivery_status: job.delivery_status,
            job_status: job.job_status,
            returned_user: returned_user,
        })
            .subscribe(function (res) {
            // console.log(res)
            _this.assignLoading.next(false);
            // const list = [...this.sortingList.result]
            // const oldSortIndex = list.findIndex((sort) => {sort.id === job.id})
            // list[oldSortIndex] = job;
            // this.sortingList.result = list;
            // this.sortingListUpdate.next({ ...this.sortingList });
            // this.notification.success(`Job Returned`);
            // this.sortingList = res;
            _this.router.navigate(["/dashboard"]);
        }, function (err) {
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.uploadImage = function (job, form) {
        var _this = this;
        // app/job_file/
        this.assignLoading.next(true);
        this.http.patch(this.apiUrl + "app/job_file/" + job + "/", form).subscribe(function (res) {
            _this.assignLoading.next(false);
            // console.log(res);
            _this.notification.success("Note Uploaded");
            // this.sortingList = res;
            // this.sortingListUpdate.next({ ...this.sortingList });
        }, function (err) {
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.getAssignLoading = function () {
        return this.assignLoading.asObservable();
    };
    SortingService.prototype.getTriggerLoading = function () {
        return this.triggerLoading.asObservable();
    };
    SortingService.prototype.getBankSortingList = function (start_date, end_date, 
    // code_id = 1,
    limit, page, courier_id) {
        var _this = this;
        if (start_date === void 0) { start_date = this.helperService.formatDate(new Date()); }
        if (end_date === void 0) { end_date = this.helperService.formatDate(new Date()); }
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        if (courier_id === void 0) { courier_id = 0; }
        var query = "?limit=" + limit + "&page=" + page;
        var data = {
            start_date: start_date,
            end_date: end_date,
            code_id: this.helperService.getBankCode(),
            status_id: 0,
            courier_id: courier_id,
        };
        // console.log(data);
        this.http
            .post(this.apiUrl + "app/bnk/sorting_list/range/" + query, data)
            .subscribe(function (res) {
            // console.log(res);
            _this.sortingList = res;
            _this.sortingListUpdate.next(__assign({}, _this.sortingList));
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.getCourierSortingList = function (start_date, end_date, limit, page
    // courier_id = 0
    ) {
        var _this = this;
        if (start_date === void 0) { start_date = this.helperService.formatDate(new Date()); }
        if (end_date === void 0) { end_date = this.helperService.formatDate(new Date()); }
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        var query = "?limit=" + limit + "&page=" + page;
        var data = {
            start_date: start_date,
            end_date: end_date,
            code_id: this.helperService.getBankCode(),
            status_id: 0,
        };
        // console.log(data);
        this.http
            .post(this.apiUrl + "app/search/sorting_list/secure_id/" + query, data)
            .subscribe(function (res) {
            console.log(res);
            _this.sortingList = res;
            _this.sortingListUpdate.next(__assign({}, _this.sortingList));
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.searchByJobId = function (jobID) {
        var _this = this;
        return jobID.pipe(debounceTime(400), distinctUntilChanged(), switchMap(function (term) { return _this.searchEntries(term); }));
    };
    SortingService.prototype.searchEntries = function (term) {
        return this.http
            .post(this.apiUrl + "app/search/sorting_list/job_id/", { job_id: term })
            .pipe(map(function (res) { return res; }));
    };
    SortingService.prototype.searchForBankByJobId = function (jobID) {
        var _this = this;
        return jobID.pipe(debounceTime(400), distinctUntilChanged(), switchMap(function (term) { return _this.searchBankEntries(term); }));
    };
    SortingService.prototype.searchBankEntries = function (term) {
        return this.http
            .post(this.apiUrl + "app/bnk/sorting_list/job_id/", {
            job_id: term,
            code_id: this.helperService.getBankCode(),
        })
            .pipe(map(function (res) { return res; }));
    };
    SortingService.prototype.getSortingListUpdate = function () {
        return this.sortingListUpdate.asObservable();
    };
    SortingService.prototype.uploadSortingList = function (list, courier_id) {
        var _this = this;
        this.excelUploading.next(true);
        // console.log(list);
        // this.http.post(`${this.apiUrl}app/add/sorting_array/`, list).subscribe(
        this.http
            .post(this.apiUrl + "app/all/upload/sorting_list/", list)
            .subscribe(function (res) {
            _this.excelUploading.next(false);
            // form.reset();
            // console.log(res);
            _this.triggerDHL(Number(courier_id));
            _this.notification.success("Submitted");
            _this.router.navigate(["/sort-list"]);
        }, function (err) {
            _this.excelUploading.next(false);
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    SortingService.prototype.getExcelUploading = function () {
        return this.excelUploading.asObservable();
    };
    SortingService.prototype.getDetailsByTrackingID = function (track_id) {
        var _this = this;
        this.http
            .post(this.apiUrl + "app/get/tracking_details/tracking_id/", {
            track_id: track_id,
        })
            .subscribe(function (data) {
            // console.log(data);
            _this.package = data;
            _this.packageUpdate.next(_this.package);
        });
    };
    SortingService.prototype.getPackageUpdate = function () {
        return this.packageUpdate.asObservable();
    };
    SortingService.prototype.getSIDPackageDetailsByRef = function (ref_id) {
        var _this = this;
        this.http
            .post(this.apiUrl + "app/track/sid_jobs/", {
            ref_id: ref_id,
        })
            .subscribe(function (data) {
            // console.log(data);
            _this.package = data;
            _this.packageUpdate.next(_this.package);
        });
    };
    // getPackageUpdate() {
    //   return this.packageUpdate.asObservable();
    // }
    SortingService.prototype.triggerDHL = function (courier_id) {
        var _this = this;
        this.triggerLoading.next(true);
        this.http
            .post(this.apiUrl + "app/create/tracking_id/", { id: courier_id })
            .subscribe(function (res) {
            _this.triggerLoading.next(false);
            _this.notification.success("Courier triggered");
            // console.log(res);
        });
    };
    SortingService.prototype.getSeal = function (job_id) {
        // console.log(job_id);
        return this.http.get(this.apiUrl + "app/job_file/" + job_id + "/");
    };
    SortingService.ngInjectableDef = i0.defineInjectable({ factory: function SortingService_Factory() { return new SortingService(i0.inject(i1.HttpClient), i0.inject(i2.HelperService), i0.inject(i3.NotificationService), i0.inject(i4.Router)); }, token: SortingService, providedIn: "root" });
    return SortingService;
}());
export { SortingService };
