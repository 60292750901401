/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page500.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./page500.component";
var styles_Page500Component = [i0.styles];
var RenderType_Page500Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Page500Component, data: {} });
export { RenderType_Page500Component as RenderType_Page500Component };
export function View_Page500Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "container my-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "img-100 mx-auto d-block"], ["src", "../../assets/img/page404.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", " col-md-8 mx-auto mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "h2", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 500 "])), (_l()(), i1.ɵted(-1, null, [" PAGE NOT FOUND! "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "text-center font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved. "])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "col-12 mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "d-block mx-auto btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["BACK TO HOME PAGE"]))], function (_ck, _v) { var currVal_0 = "/dashboard"; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_Page500Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page500", [], null, null, null, View_Page500Component_0, RenderType_Page500Component)), i1.ɵdid(1, 114688, null, 0, i3.Page500Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Page500ComponentNgFactory = i1.ɵccf("app-page500", i3.Page500Component, View_Page500Component_Host_0, {}, {}, []);
export { Page500ComponentNgFactory as Page500ComponentNgFactory };
