import { Subject } from "rxjs";
import * as i0 from "@angular/core";
var ErrorService = /** @class */ (function () {
    function ErrorService() {
        this.errorListener = new Subject();
    }
    ErrorService.prototype.getErrorListener = function () {
        return this.errorListener.asObservable();
    };
    ErrorService.prototype.throwError = function (message) {
        this.errorListener.next(message);
    };
    ErrorService.prototype.handleError = function () {
        this.errorListener.next(null);
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
