import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SortingService } from "src/app/shared/sorting.service";
import { ViewPackageDialogComponent } from "src/app/sorting-list/view-package-dialog/view-package-dialog.component";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import { TrackAgentDialogComponent } from "src/app/track-agent-dialog/track-agent-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-track-package",
  templateUrl: "./track-package.component.html",
  styleUrls: ["./track-package.component.scss"],
})
export class TrackPackageComponent implements OnInit {
  form: FormGroup;
  // mode: string;
  title: string;
  adminTrack: boolean = false;
  packageSub = new Subscription();

  constructor(
    private fb: FormBuilder,
    private packageDialog: MatDialog,
    private sortService: SortingService,
    private router: Router // private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.router.url == "/track-package") {
      this.adminTrack = true;
    }

    // this.checkMode();
    this.form = this.fb.group({
      // id: [""],
      track_id: ["", [Validators.required]],
    });
  }

  ngOnDestroy() {
    this.packageSub.unsubscribe();
  }

  // checkMode() {
  //   this.route.paramMap.subscribe((paramMap: ParamMap) => {
  //     if (paramMap.has("id")) {
  //       let id = paramMap.get("id");
  //       this.mode = "edit";
  //       this.title = "Edit Department";
  //       // this.earningForm.patchValue({ id: id });
  //       this.sortService.getDepartmentByID(id);
  //       this.sortService.getDepartmentUpdate().subscribe((department) => {
  //         // this.logger.log(department);
  //         // this.department = department;
  //         // // console.log(this.earning);
  //         this.departmentForm.setValue({
  //           id: department.id,
  //           name: department.name,
  //         });
  //       });
  //       // this.logger.log(this.earningForm.value);
  //     } else {
  //       this.mode = "create";
  //       this.title = "New Department";
  //     }
  //   });
  // }

  onSave() {
    if (this.form.invalid) {
      return;
    }
    const track_id: string = this.form.value.track_id;
    let res = track_id.slice(0, 3);
    if (res == "ref") {
      this.viewSIDPackage(track_id);
    } else {
      this.viewExtPackage(track_id);
    }
  }

  viewExtPackage(track_id) {
    this.sortService.getDetailsByTrackingID(track_id);
    this.packageSub = this.sortService.getPackageUpdate().subscribe((res) => {
      const dialogRef = this.packageDialog.open(ViewPackageDialogComponent, {
        width: "80%",
        panelClass: "confirm-dialog-container",
        disableClose: true,
        // position: { top: "10px" },
        data: {
          job: res,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.packageSub.unsubscribe();
        // // console.log("The dialog was closed");
        // this.animal = result;
      });
    });
  }

  viewSIDPackage(item) {
    this.sortService.getSIDPackageDetailsByRef(item);
    this.packageSub = this.sortService.getPackageUpdate().subscribe((res) => {
      const dialogRef = this.packageDialog.open(TrackAgentDialogComponent, {
        width: "80%",
        panelClass: "confirm-dialog-container",
        disableClose: true,
        // position: { top: "10px" },
        data: { ...res },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.packageSub.unsubscribe();
        // // console.log("The dialog was closed");
        // this.animal = result;
      });
    });
  }
}
