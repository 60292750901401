var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
var TrackAgentDialogComponent = /** @class */ (function () {
    function TrackAgentDialogComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        // google maps zoom level
        this.zoom = 16;
        // currentPos: Point = {
        //   latitude: 6.6446379,
        //   longitude: 3.3033781,
        //   icon: "../assets/img/two_wheeler-black-18dp.svg",
        //   animate: "DROP",
        // };
        this.points = [];
    }
    TrackAgentDialogComponent.prototype.ngOnInit = function () {
        this.info = {
            shipment_origin: this.data.shipment_origin,
            shipment_destination: this.data.shipment_destination,
            ref_no: this.data.ref_no,
            dispatch_agent: this.data.dispatch_agent,
        };
        if (this.data.data.length > 0) {
            this.points = this.data.data.map(function (point) {
                return {
                    longitude: Number(point.longitude),
                    latitude: Number(point.latitude),
                };
            });
            this.startPos = this.points[0];
            this.currentPos = __assign({}, this.points[this.points.length - 1], { icon: "../assets/img/two_wheeler-black-18dp.svg", animate: "DROP" });
        }
        // // this.data;
        // this.points.splice(25, 1);
        // console.log(this.data);
        // console.log(this.points);
        // console.log(this.startPos);
        // console.log(this.currentPos);
        // console.log(this.info);
    };
    TrackAgentDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close(false);
    };
    TrackAgentDialogComponent.prototype.mapClicked = function (value) {
        // console.log(value);
    };
    return TrackAgentDialogComponent;
}());
export { TrackAgentDialogComponent };
