import { HttpRequest, HttpEventType, HttpClient } from "@angular/common/http";
import { of, Subject } from "rxjs";
import { shareReplay, map, catchError } from "rxjs/operators";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { FormGroup, FormControl, } from "@angular/forms";
import * as moment from "moment";
import { AuthService } from "../Auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../Auth/auth.service";
var HelperService = /** @class */ (function () {
    function HelperService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.isCollapsed = new Subject();
        this.banksUpdate = new Subject();
    }
    HelperService.prototype.getLoginResponse = function () {
        return JSON.parse(localStorage.getItem("login_response"));
    };
    HelperService.prototype.getAssignedCourier = function () {
        return JSON.parse(localStorage.getItem("assigned_courier"));
    };
    HelperService.prototype.getShipperID = function () {
        return JSON.parse(localStorage.getItem("shipper_id"));
    };
    HelperService.prototype.isDateValid = function (date) {
        // check if date is valid
        return !isNaN(new Date(date).valueOf());
    };
    HelperService.prototype.copyToClipboard = function (text) {
        // return the observable, so you can notify with the then callback
        // return window.navigator.clipboard.writeText(text);
    };
    HelperService.prototype.getSelection = function () {
        window.getSelection().toString();
    };
    HelperService.prototype.checkDarkMode = function () {
        return (window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches);
    };
    HelperService.prototype.toggleNav = function () {
        this.isCollapsed.next(true);
    };
    HelperService.prototype.getToggleValue = function () {
        return this.isCollapsed.asObservable();
    };
    HelperService.prototype.getUser = function () {
        return JSON.parse(localStorage.getItem("user_id"));
    };
    HelperService.prototype.getUserGroup = function () {
        return JSON.parse(localStorage.getItem("user_group"));
    };
    HelperService.prototype.getUsername = function () {
        return JSON.parse(localStorage.getItem("username"));
    };
    HelperService.prototype.getFirstname = function () {
        return JSON.parse(localStorage.getItem("firstname"));
    };
    HelperService.prototype.getLastname = function () {
        return JSON.parse(localStorage.getItem("lastname"));
    };
    HelperService.prototype.getPhone = function () {
        return JSON.parse(localStorage.getItem("phone"));
    };
    HelperService.prototype.getBankCode = function () {
        return JSON.parse(localStorage.getItem("bank_code"));
    };
    HelperService.prototype.getMomentTime = function (date) {
        return moment(date).format("lll");
    };
    HelperService.prototype.timeFromNow = function (time) {
        // return moment(time).fromNow();
    };
    HelperService.prototype.dashboardCalendar = function (time) {
        // return moment(time).startOf("month").calendar();
    };
    HelperService.prototype.formatDate = function (date) {
        return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    };
    // get bank name by passing user's paystack bank code
    HelperService.prototype.getBank = function (account_code) {
        var desiredBank;
        // console.log(account_code);
        this.getBanksList();
        desiredBank = this.banks.filter(function (bank) { return bank.code == account_code; });
        // console.log(desiredBank);
        return desiredBank[0].name;
    };
    // Get List of Banks from Paystack
    HelperService.prototype.getBanksList = function () {
        var _this = this;
        this.http
            .get("https://api.paystack.co/bank/")
            .pipe(map(function (res) { return res.data; }), shareReplay())
            .subscribe(function (response) {
            _this.banks = response;
            _this.banksUpdate.next(_this.banks);
        }, function (error) {
            _this.banks = [];
            _this.banksUpdate.next(_this.banks);
        });
    };
    HelperService.prototype.getBankUpdate = function () {
        return this.banksUpdate.asObservable();
    };
    HelperService.prototype.exportExcel = function (data, title, tableHeaders) {
        var options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalseparator: ".",
            showLabels: true,
            showTitle: false,
            useBom: true,
            noDownload: false,
            headers: tableHeaders,
            title: title,
        };
        new AngularCsv(data, title, options);
    };
    HelperService.prototype.lowerCase = function (string) {
        return string.toLowerCase();
    };
    HelperService.prototype.firstUpper = function (string) {
        var username = string.toLowerCase();
        return "" + username.charAt(0).toUpperCase() + username.slice(1);
    };
    HelperService.prototype.upperCase = function (string) {
        return string.toUpperCase();
    };
    HelperService.prototype.longRequest = function () {
        var request = new HttpRequest("POST", "/api/test-request", {}, { reportProgress: true });
        this.http.request(request).subscribe(function (event) {
            if (event.type === HttpEventType.DownloadProgress) {
                // console.log("Download progress event", event);
            }
            if (event.type === HttpEventType.UploadProgress) {
                // console.log("Upload progress event", event);
            }
            if (event.type === HttpEventType.Response) {
                // console.log("response received...", event.body);
            }
        });
    };
    HelperService.prototype.merger = function (array) {
        // if new Array includes an object that has same name as array, add the counts, else, push
        var result = [];
        // console.log(result);
        array.forEach(function (element) {
            var dontAdd = result.filter(function (data) { return element.status === data.status; });
            // console.log(dontAdd);
            if (dontAdd.length !== 0) {
                var updatedResult = result.slice();
                var oldItemIndex = updatedResult.findIndex(function (c) { return c.status === element.status; });
                updatedResult[oldItemIndex].counts += element.counts;
                result = updatedResult;
            }
            else {
                result.push(element);
            }
            // console.log(dontAdd);
        });
        // console.log(result);
        return result;
    };
    HelperService.prototype.mergeStatus = function (array) {
        // if new Array includes an object that has same name as array, add the counts, else, push
        var result = [];
        array.forEach(function (element) {
            var dontAdd = result.filter(function (data) { return element.status === data.status; });
            if (dontAdd.length !== 0) {
                var updatedResult = result.slice();
                var oldItemIndex = updatedResult.findIndex(function (c) { return c.status === element.status; });
                updatedResult[oldItemIndex].counts += element.counts;
                result = updatedResult;
            }
            else {
                result.push(element);
            }
            // console.log(dontAdd);
        });
        // console.log(result);
        return result;
    };
    HelperService.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        //{1}
        Object.keys(formGroup.controls).forEach(function (field) {
            //{2}
            var control = formGroup.get(field); //{3}
            if (control instanceof FormControl) {
                //{4}
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                //{5}
                _this.validateAllFormFields(control); //{6}
            }
        });
    };
    HelperService.prototype.validateEmailIsTaken = function (ctrl) {
        console.log(ctrl);
        this.authService.isEmailTaken(ctrl.value);
        // .pipe(
        //   map((isTaken) => (isTaken ? { isTaken: true } : null)),
        //   catchError(() => null)
        // );
        return this.authService.isUsernameTaken(ctrl.value).pipe(map(function (isTaken) { return (isTaken ? { isTaken: true } : null); }), catchError(function () { return null; }));
    };
    HelperService.prototype.validate = function (control) {
        console.log(control);
        return of({ custom: true });
    };
    HelperService.prototype.validateUsernameIsTaken = function (ctrl) {
        return this.authService.isUsernameTaken(ctrl.value).pipe(map(function (isTaken) { return (isTaken ? { isTaken: true } : null); }), catchError(function () { return null; }));
    };
    HelperService.ngInjectableDef = i0.defineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
export { HelperService };
