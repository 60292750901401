import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Platform } from "@angular/cdk/platform";

@Injectable({
  providedIn: "root",
})
export class PwaService {
  promptEvent: any;
  allowUpdates = false;
  deferredPrompt: any;

  constructor(private swUpdate: SwUpdate, private platform: Platform) {
    // window.addEventListener("beforeinstallprompt", (event) => {
    //   this.promptEvent = event;
    // });

    swUpdate.available.subscribe((event) => {
      // notify user that update is available
      alert(
        "New Update available! Reload app to see the latest juicy changes."
      );
      this.allowUpdates = true;
      if (this.allowUpdates) {
        window.location.reload();
      }
    });
  }

  initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener("beforeinstallprompt", (event: any) => {
        event.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = event;
        this.promptEvent = event;
        // this.openPromptComponent('android');
      });
    } else if (this.platform.IOS) {
      const isInStandaloneMode =
        "standalone" in window.navigator && window.navigator["standalone"];
      if (!isInStandaloneMode) {
        // this.openPromptComponent('ios');
      }
    } else {
      window.addEventListener("beforeinstallprompt", (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        // this.openPromptComponent('android');
      });
    }
  }
}
