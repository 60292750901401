<div>
  <div class="content-container">
    <i (click)="closeDialog()" class=" fa fa-times"></i>
    <!-- <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon> -->
    <span class="content-span full-width">{{data.message}}</span>
  </div>
  <div class="container">
    <div class="row pb-4 justify-content-center">
      <div class="col-5 p-0">
        <button class="btn btn-danger btn-block" mat-raised-button id="no-button" [mat-dialog-close]="false">NO</button>
      </div>
      <div class="col-1"></div>
      <div class="col-5 p-0">
        <button class="btn btn-primary btn-block" mat-raised-button id="yes-button"
          [mat-dialog-close]="true">YES</button>
      </div>
    </div>
  </div>
</div>
