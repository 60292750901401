import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-track-agent-dialog",
  templateUrl: "./track-agent-dialog.component.html",
  styleUrls: ["./track-agent-dialog.component.scss"],
})
export class TrackAgentDialogComponent implements OnInit {
  // google maps zoom level
  zoom: number = 16;

  // initial center position for the map
  startPos: Point;
  // startPos: Point = {
  //   latitude: 6.6089543,
  //   longitude: 3.3088351,
  //   // icon: "../assets/img/two_wheeler-black-18dp.svg",
  //   // animate: "DROP",
  // };

  // Current center position for the map
  currentPos: Point;
  // currentPos: Point = {
  //   latitude: 6.6446379,
  //   longitude: 3.3033781,
  //   icon: "../assets/img/two_wheeler-black-18dp.svg",
  //   animate: "DROP",
  // };

  points: Point[] = [];
  // points: Point[] = [
  //   // this.currentPos,
  //   {
  //     latitude: 6.6089543,
  //     longitude: 3.3088351,
  //   },
  //   // {
  //   //   lat: 50.083202,
  //   //   lng: 14.430994,
  //   // },
  //   {
  //     latitude: 6.6346379,
  //     longitude: 3.3044181,
  //   },
  //   // {
  //   //   lat: 50.083491,
  //   //   lng: 14.430569,
  //   // },
  //   {
  //     latitude: 6.6446379,
  //     longitude: 3.3033781,
  //     // icon: "default-icon.png",
  //   },
  // ];
  info: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TrackAgentDialogComponent>
  ) {}

  ngOnInit() {
    this.info = {
      shipment_origin: this.data.shipment_origin,
      shipment_destination: this.data.shipment_destination,
      ref_no: this.data.ref_no,
      dispatch_agent: this.data.dispatch_agent,
    };
    if (this.data.data.length > 0) {
      this.points = this.data.data.map((point) => {
        return {
          longitude: Number(point.longitude),
          latitude: Number(point.latitude),
        };
      });
      this.startPos = this.points[0];
      this.currentPos = {
        ...this.points[this.points.length - 1],
        icon: "../assets/img/two_wheeler-black-18dp.svg",
        animate: "DROP",
      };
    }
    // // this.data;
    // this.points.splice(25, 1);
    // console.log(this.data);
    // console.log(this.points);
    // console.log(this.startPos);
    // console.log(this.currentPos);
    // console.log(this.info);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  mapClicked(value) {
    // console.log(value);
  }
}

interface Point {
  latitude: any;
  longitude: any;
  icon?: any;
  animate?: string;
}
