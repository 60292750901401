/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row align-items-center justify-content-xl-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-xl-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "copyright text-center text-xl-left text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" \u00A9 ", " "])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "font-weight-bold ml-1"], ["href", "https://www.secureidltd.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "siddiglog"], ["class", "sid-footer-logo"], ["src", "assets\\img\\siddiglog.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "col-xl-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "ul", [["class", "nav nav-footer justify-content-center justify-content-xl-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["alt", "logo"], ["class", "footer-logo mr-2"], ["src", "../../../assets/img/INSIGHT SID 512.webp"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" Insight ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.test, "yyyy")); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.version; _ck(_v, 13, 0, currVal_1); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
