var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { ERROR_MESSAGE_DICTIONARY } from "./error-message";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { HelperService } from "./helper.service";
import { LogService } from "./log.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./notification.service";
import * as i3 from "./helper.service";
import * as i4 from "./log.service";
import * as i5 from "@angular/router";
var ProfileService = /** @class */ (function () {
    function ProfileService(http, notification, helpService, logger, router) {
        this.http = http;
        this.notification = notification;
        this.helpService = helpService;
        this.logger = logger;
        this.router = router;
        this.profiles = [];
        this.profilesUpdated = new Subject();
        this.profileUpdate = new Subject();
        this.apiUrl = environment.apiUrl;
    }
    ProfileService.prototype.getProfileById = function (id) {
        // console.log("here now 1");
        var userGroup = this.helpService.getUserGroup();
        // console.log(userGroup);
        switch (userGroup) {
            case 1:
                // code block
                this.getSidProfileByID(id);
                break;
            case 2:
                // code block
                this.getSidProfileByID(id);
                break;
            case 3:
                // code block
                this.getSidProfileByID(id);
                break;
            case 4:
                // code block
                this.getSidProfileByID(id);
                break;
            case 5:
                // code block
                this.getUserProfileByID(id);
                break;
            case 6:
                // code block
                this.getUserProfileByID(id);
                break;
            case 7:
                // code block
                this.getUserProfileByID(id);
                break;
            default:
                // code block
                this.getUserProfileByID(id);
                break;
        }
    };
    ProfileService.prototype.getUserProfileByID = function (user_id) {
        // const profile = this.profiles.filter(theProfile => theProfile.user === id);
        // return profile[0];
        // console.log("here now 2");
        var _this = this;
        return this.http
            .post(this.apiUrl + "app/get/user/profile/", { user_id: user_id })
            .subscribe(function (data) {
            if (data.length > 0) {
                _this.profile = data[0];
                _this.saveProfileData(_this.profile.firstname, _this.profile.lastname, _this.profile.username, _this.profile.phone
                // this.profile.bank_code
                );
                _this.saveBankDetails(_this.profile.bank_code, _this.profile.shipper_id, _this.profile.assigned_courier);
                _this.profileUpdate.next(__assign({}, _this.profile));
            }
            // console.log(data);
        }, function (err) {
            return;
        });
    };
    ProfileService.prototype.getSidProfileByID = function (user_id) {
        // const profile = this.profiles.filter(theProfile => theProfile.user === id);
        // return profile[0];
        var _this = this;
        this.http
            .post(this.apiUrl + "app/get/sid_user/profile/", { user_id: user_id })
            .subscribe(function (data) {
            // this.logger.log(data);
            // console.log(data);
            _this.profile = data[0];
            _this.saveProfileData(_this.profile.firstname, _this.profile.lastname, _this.profile.username, _this.profile.phone
            // null
            );
            // // console.log(this.profile);
            _this.profileUpdate.next(__assign({}, _this.profile));
        }, function (err) {
            return;
        });
    };
    ProfileService.prototype.getProfileListener = function () {
        return this.profileUpdate.asObservable();
    };
    ProfileService.prototype.saveProfileData = function (firstname, lastname, username, phone
    // bank_code: string
    ) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // console.log("here now 3");
                    return [4 /*yield*/, localStorage.setItem("firstname", JSON.stringify(firstname))];
                    case 1:
                        // console.log("here now 3");
                        _a.sent();
                        return [4 /*yield*/, localStorage.setItem("lastname", JSON.stringify(lastname))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, localStorage.setItem("username", JSON.stringify(username))];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, localStorage.setItem("phone", JSON.stringify(phone))];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileService.prototype.saveBankDetails = function (bank_code, shipper_id, assigned_courier) {
        // console.log("here now 3");
        if (assigned_courier) {
            localStorage.setItem("assigned_courier", JSON.stringify(assigned_courier));
        }
        if (bank_code) {
            localStorage.setItem("bank_code", JSON.stringify(bank_code));
        }
        if (shipper_id) {
            localStorage.setItem("shipper_id", JSON.stringify(shipper_id));
        }
    };
    // getOtherProfileById(id) {
    //   this.http.get<any>(`${this.apiUrl}loyalty/profile/${id}`).subscribe(
    //     (data) => {
    //       this.profile = data;
    //       this.profileUpdate.next({ ...this.profile });
    //       // return data;
    //     },
    //     (err) => {
    //       return;
    //     }
    //   );
    // }
    // getAllProfiles() {
    //   // get Earnings List
    //   this.http.get<any[]>(`${this.apiUrl}loyalty/profile/`).subscribe(
    //     (res: any[]) => {
    //       this.logger.log(res);
    //       this.profiles = res;
    //       this.profilesUpdated.next([...this.profiles]);
    //     },
    //     (err: any) => {
    //       switch (err.status) {
    //         case 400: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
    //           break;
    //         }
    //         case 401: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
    //           break;
    //         }
    //         case 403: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
    //           break;
    //         }
    //         case 404: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
    //           break;
    //         }
    //         case 415: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
    //           break;
    //         }
    //         case 500: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
    //           break;
    //         }
    //         case 503: {
    //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
    //           break;
    //         }
    //       }
    //       // this.isUploading = false;
    //     }
    //   );
    // }
    ProfileService.prototype.changeDp = function (image) {
        var _this = this;
        var id = this.helpService.getUser();
        this.logger.log(image);
        // // console.log(form);
        this.http.patch(this.apiUrl + "app/users/" + id + "/", { image: image }).subscribe(function (data) {
            _this.logger.log(data);
            _this.notification.success(":: Profile Updated Successfully");
            _this.router.navigate(["/user-profile"]);
            window.location.reload();
        }, function (err) {
            window.location.reload();
            // console.log(err);
            // console.log(err.error);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    ProfileService.prototype.updateProfile = function (form) {
        var _this = this;
        var id = this.helpService.getUser();
        this.logger.log(form);
        // // console.log(form);
        this.http.patch(this.apiUrl + "app/users/" + id + "/", form).subscribe(function (data) {
            _this.logger.log(data);
            _this.notification.success(":: Profile Updated Successfully");
            _this.router.navigate(["/user-profile"]);
        }, function (err) {
            _this.logger.error(err);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient), i0.inject(i2.NotificationService), i0.inject(i3.HelperService), i0.inject(i4.LogService), i0.inject(i5.Router)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
