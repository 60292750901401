import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Subject, BehaviorSubject } from "rxjs";
import { map, switchMap, mergeMap } from "rxjs/operators";
import { AuthLogin, AuthSignUp } from "../Auth/auth-model";
import { DialogService } from "../shared/dialog.service";
import { NotificationService } from "../shared/notification.service";
import { LogService } from "../shared/log.service";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";
import { HelperService } from "../shared/helper.service";
import { ProfileService } from "../shared/profile.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ErrorService } from "../shared/error/error.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isUploading: boolean;
  private apiUrl = environment.apiUrl;
  private errorMessage: string;
  private loginInvalid: boolean;
  private accessToken: string;
  private currentUserId: number;
  isAuthenticated: boolean;
  private id: number;
  private isRootAdmin: boolean;
  private isAdmin: boolean;
  private isLoadingListener = new Subject<boolean>();
  private authStatusListener = new Subject<boolean>();
  private cardInvalid: boolean;
  private tokenTimer: any;
  private refreshToken: string;
  accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    // private dialog: DialogService,
    private errorService: ErrorService,
    private helpService: HelperService,
    private notification: NotificationService,
    private logger: LogService,
    private router: Router,
    private http: HttpClient,
    private profileService: ProfileService
  ) {}

  register(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };

    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/bank/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createBank(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/bank/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createBankStaff(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/bankstaff/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createSidMgt(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/sid_mgt/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createSidStaff(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/sid_staff/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createMgtAdmin(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/mgt_admin/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res: any) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  createDelivery(registerInfo: any /*AuthSignUp*/) {
    this.isUploading = true;
    const loginInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      password: registerInfo.password,
    };
    const regInfo = {
      username: this.helpService.lowerCase(registerInfo.username),
      first_name: this.helpService.firstUpper(registerInfo.first_name),
      last_name: this.helpService.upperCase(registerInfo.last_name),
      email: this.helpService.lowerCase(registerInfo.email),
      password: registerInfo.password,
      groups: [],
    };

    const updateInfo = {
      sex: registerInfo.sex,
      phone: registerInfo.phone,
      address: registerInfo.address,
      birth_date: null,
      // birth_date: this.helpService.formatDate(registerInfo.birth_date),
      bank_code: "access",
      // bank_code: registerInfo.bank_code,
      city: registerInfo.bank_code,
      country: registerInfo.country,
      postal_code: registerInfo.postal_code,
      account_number: registerInfo.account_number,
      account_code: registerInfo.account_code,
      // user_type: registerInfo.user_type,
      user_type: "BNK",
      profile_status: true,
    };

    // console.log({ ...regInfo, ...updateInfo });
    this.http
      .post<{
        id: number;
        username: string;
        password: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: number[];
      }>(`${this.apiUrl}auth/delivery_agent/register/`, regInfo)
      // .subscribe((res) => {
      //   // console.log(res);
      // });
      .pipe(
        switchMap((res) => {
          // console.log(res);
          this.id = res.id;
          return this.http.patch<any>(
            `${this.apiUrl}app/users/${this.id}/`,
            updateInfo
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.login(loginInfo);
          // this.router.navigate(["/dashboard"]);
          this.isUploading = false;
          // registerInfo.reset();
          // this.router.navigate(["complete-registration/"]);
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  completeReg(registerInfo) {
    const updateInfo = {
      sex: registerInfo.value.sex,
      phone: registerInfo.value.phone,
      address: registerInfo.value.address,
      card_id: registerInfo.value.card_id,
      // referral_email: '',
      referral_email: registerInfo.value.referral_email,
      // birth_date: registerInfo.value.birth_date.toLocaleDateString(),
      birth_date: null,
      profile_status: true,
    };
    this.logger.log(updateInfo);
    return this.http
      .put<any>(`${this.apiUrl}loyalty/user/update/${this.id}/`, updateInfo)
      .subscribe(
        (res) => {
          this.logger.log(res);
          this.notification.success(`:: Succesfully Created account`);
          this.isUploading = false;
          registerInfo.reset();
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          this.isUploading = false;
        }
      );
  }

  checkCard(cardID) {
    this.isLoadingListener.next(true);
    this.cardInvalid = false;
    return this.http.post<any>(this.apiUrl + "teller/check/card/", {
      card_id: cardID.toUpperCase(),
    });
  }

  isEmailTaken(email: string) {
    console.log(email);
    return this.http
      .post(`${this.apiUrl}auth/check-unique/`, {
        field_type: "email",
        field_value: email,
      })
      .pipe(
        // delay(1000),
        map((res) => res),
        map((res: any) => res.status)
        // map((users: any[]) => users.filter((user) => user.email === email)),
        // map((users: any[]) => !users.length)
      )
      .subscribe((res) => console.log(res));
  }

  isUsernameTaken(username: string) {
    return this.http
      .post(`${this.apiUrl}auth/check-unique/`, {
        field_type: "username",
        field_value: username,
      })
      .pipe(
        // delay(1000),
        map((res) => res),
        map((res: any) => res.status)
        // map((users: any[]) => !users.length)
      );
  }

  getToken() {
    return this.accessToken;
  }

  getIsAuth() {
    return JSON.parse(localStorage.getItem("isAuth"));
  }

  getUserId() {
    return this.currentUserId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getIsLoadingListener() {
    return this.isLoadingListener.asObservable();
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.accessToken = authInformation.token;
      this.isAuthenticated = true;
      this.currentUserId = Number(authInformation.userId);
      this.timedLogOut(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  private timedLogOut(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(
    token: string,
    refreshToken: string,
    expirationDate: Date,
    userId: number,
    userGroup: number,
    firstname,
    lastname,
    username,
    phone,
    assigned_courier,
    bank_code,
    shipper_id,
    loginResponse
  ) {
    localStorage.setItem("login_response", JSON.stringify(loginResponse));
    localStorage.setItem("isAuth", JSON.stringify(true));
    localStorage.setItem("tokenAccess", token);
    localStorage.setItem("token", token);
    localStorage.setItem("tokenRefresh", refreshToken);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("user_id", JSON.stringify(userId));
    localStorage.setItem("user_group", JSON.stringify(userGroup));
    localStorage.setItem("firstname", JSON.stringify(firstname));
    localStorage.setItem("lastname", JSON.stringify(lastname));
    localStorage.setItem("username", JSON.stringify(username));
    localStorage.setItem("phone", JSON.stringify(phone));
    if (assigned_courier) {
      localStorage.setItem(
        "assigned_courier",
        JSON.stringify(assigned_courier)
      );
    }
    if (bank_code) {
      localStorage.setItem("bank_code", JSON.stringify(bank_code));
    }
    if (shipper_id) {
      localStorage.setItem("shipper_id", JSON.stringify(shipper_id));
    }
  }

  private clearAuthData() {
    // localStorage.removeItem("isAuth");
    // localStorage.removeItem("tokenRefresh");
    // localStorage.removeItem("tokenAccess");
    // localStorage.removeItem("expiration");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("userGroup");
    localStorage.clear();
  }

  private getAuthData() {
    const token = localStorage.getItem("tokenAccess");
    const refresh = localStorage.getItem("tokenRefresh");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("user_id");
    const userGroup = localStorage.getItem("user_group");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userGroup: userGroup,
    };
  }

  getUserID() {
    const token = localStorage.getItem("tokenAccess");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("user_id");
    if (!token || !expirationDate) {
      return;
    }
    return userId;
  }

  getUserGroupExt() {
    const token = localStorage.getItem("tokenAccess");
    const expirationDate = localStorage.getItem("expiration");
    const userGroup = JSON.parse(localStorage.getItem("user_group"));
    if (!token || !expirationDate) {
      return;
    }
    return userGroup;
  }

  getIsAdmin() {
    return this.isAdmin;
  }

  getIsRootAdmin() {
    return this.isRootAdmin;
  }

  getJWT(loginInfo: AuthLogin) {
    return this.http.post<{ refresh: string; access: string }>(
      `${this.apiUrl}api/token/`,
      loginInfo
    );
  }

  onRefreshToken() {
    const refresh = this.refreshToken || localStorage.getItem("tokenRefresh");
    return this.http
      .post<{ access: string }>(`${this.apiUrl}api/refresh/token/`, { refresh })
      .subscribe((data) => {
        // get new Token and set new token
        // this.logger.log(data);
        // // console.log(data);
        this.accessToken = data.access;
        const expiresInDuration = 1200;
        const now = new Date();
        const expirationDate = new Date(
          now.getTime() + expiresInDuration * 1000
        );
        localStorage.setItem("tokenAccess", this.accessToken);
        localStorage.setItem("expiration", expirationDate.toISOString());
        // this.router.navigate(["/dashboard"]);
      });
  }

  onRefreshToken2() {
    const refresh = this.refreshToken || localStorage.getItem("tokenRefresh");
    return this.http.post<{ access: string }>(
      `${this.apiUrl}api/refresh/token/`,
      { refresh }
    );
    // .subscribe((data) => {
    //   // get new Token and set new token
    //   // this.logger.log(data);
    //   // // console.log(data);
    //   this.accessToken = data.access;
    //   const expiresInDuration = 1200;
    //   const now = new Date();
    //   const expirationDate = new Date(
    //     now.getTime() + expiresInDuration * 1000
    //   );
    //   localStorage.setItem("tokenAccess", this.accessToken);
    //   localStorage.setItem("expiration", expirationDate.toISOString());
    //   // this.router.navigate(["/dashboard"]);
    // });
  }

  getUserGroup(res: any) {
    if (res.groups[0] === 1) {
      this.isRootAdmin = true;
      this.isAdmin = false;
    } else if (res.groups[0] === 2) {
      this.isAdmin = true;
      this.isRootAdmin = false;
    } else {
      // something something
    }
  }

  setExpirationDate() {
    const expiresInDuration = 1200;
    const now = new Date();
    const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
    return expirationDate;
  }

  login(form: AuthLogin) {
    this.http
      .post<{ id: string; groups: any[]; refresh: string; access: string }>(
        `${this.apiUrl}auth/login/`,
        form
      )
      .subscribe(
        (response: any) => {
          this.getUserGroup(response);
          this.refreshToken = response.refresh;
          this.accessToken = response.access;
          this.logger.log(response);
          if (this.accessToken) {
            // this.timedLogOut(expiresInDuration);
            this.currentUserId = Number(response.id);
            // this.profileService.getProfileById(this.currentUserId);
            this.isAuthenticated = true;
            this.authStatusListener.next(true);
            const expirationDate = this.setExpirationDate();
            this.saveAuthData(
              this.accessToken,
              this.refreshToken,
              expirationDate,
              this.currentUserId,
              response.groups[0],
              response.firstname,
              response.lastname,
              response.username,
              response.phone,
              response.assigned_courier,
              response.bank_code,
              response.shipper_id,
              response
            );
            this.router.navigate(["/dashboard"]);
            this.isLoadingListener.next(false);
            // isTokenExpired
            // let jdh = new JwtHelperService();
            // console.log(jdh.isTokenExpired(this.accessToken));
            // console.log(jdh.decodeToken(this.accessToken));
          }
          // this.ngxService.startBackgroundLoader('loader-01');
        },
        (error) => {
          // console.log(error)
          // console.log(error.error)
          // this.isLoading = false;
          this.isLoadingListener.next(false);
          if (error.status == 401 && error.error.message) {
            this.errorMessage = error.error.message;
            this.errorService.throwError(this.errorMessage);
            this.logger.log(this.errorMessage);
          } else {
            this.errorMessage =
              error.error.message || "An unknown error occured";
            this.errorService.throwError(this.errorMessage);
            this.logger.log(this.errorMessage);
          }
        }
      );
  }

  changePassword(body: any /*PasswordChange*/) {
    // console.log(body)
    this.http.put(`${this.apiUrl}auth/change/p/`, body).subscribe(
      (data) => {
        // console.log(data)
        this.router.navigate(["/login"]);
      },
      (err) => {
        // console.log(err)
        // console.log(err.error)
      }
    );
  }

  logout() {
    this.accessToken = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.currentUserId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    localStorage.clear();
    this.router.navigate(["/login"]);
    return this.http.get(`${this.apiUrl}auth/logout/`).subscribe(
      (res) => {
        this.logger.log(res);
      },
      (err: any) => {
        this.logger.log(err);
      }
    );
  }
}
