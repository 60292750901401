<mat-toolbar>
  <span>Package Details</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  <span><i class="fa fa-window-close" (click)=closeDialog()></i></span>
</mat-toolbar>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 mx-auto">
      <div class="card-wrapper">
        <ng-container *ngIf="data.job.length; else elseTemplate">

          <div class="container">
            <ul class="progress-tracker progress-tracker--vertical progress-tracker--text">
              <li *ngFor="let item of data.job; index as i" class="progress-step is-complete">
                <div class="progress-marker" [attr.data-text]="i + 1"></div>
                <div class="progress-text">
                  <h4 class="progress-title">{{item.track_status}}</h4>
                  <p>
                    @ {{item.current_location}}
                    on <span *ngIf="item?.track_date"> {{ item?.track_date }} </span>
                    by <span *ngIf="item?.track_time"> {{ item?.track_time }} </span>
                  </p>
                  <span *ngIf="item?.track_signatory !== ' '">signed by: {{ item?.track_signatory }} </span>
                </div>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <p class="text-center">
            The package you want to view is not available
          </p>
        </ng-template>


      </div>
    </div>
  </div>
</div>
