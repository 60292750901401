import { TrackPackageComponent } from "src/app/courier/track-package/track-package.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { AuthInterceptor } from "./Auth/auth-interceptor";
// import { ErrorInterceptor } from "./shared/error-interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { ToastrModule } from "ngx-toastr";
import { BrowserModule } from "@angular/platform-browser";
import { MatDialogModule } from "@angular/material";
import { SharedModule } from "./shared/shared.module";
import { MatConfirmDialogComponent } from "./shared/mat-confirm-dialog/mat-confirm-dialog.component";
import { ErrorComponent } from "./shared/error/error.component";
// import { StaffListComponent } from "./staff/staff-list/staff-list.component";
import { ViewPackageDialogComponent } from "./sorting-list/view-package-dialog/view-package-dialog.component";
import { AgmCoreModule } from "@agm/core";
import { TrackAgentDialogComponent } from "./track-agent-dialog/track-agent-dialog.component";
import { Page404Component } from "./page404/page404.component";
import { Page500Component } from "./page500/page500.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DeliveryNoteComponent } from "./delivery-note/delivery-note.component";
import { DeliveryAgentListComponent } from "./courier/delivery-agent-list/delivery-agent-list.component";
import { ErrorInterceptor } from "./shared/error-interceptor";
// import { JwtModule } from "@auth0/angular-jwt";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MatDialogModule,
    GooglePlaceModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDftZ_BD31EheXOA57QjWo21yMEC4yNKXk",
      libraries: ["places"],
    }),
    ToastrModule.forRoot(),
    SharedModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    // StaffListComponent,
    ViewPackageDialogComponent,
    TrackAgentDialogComponent,
    TrackPackageComponent,
    Page404Component,
    Page500Component,
  ],
  entryComponents: [
    TrackAgentDialogComponent,
    MatConfirmDialogComponent,
    ErrorComponent,
    ViewPackageDialogComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
