import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LogService {
  constructor() {}

  log(data: any) {
    // console.log(data);
  }

  error(data: any) {
    console.error(data);
  }
}
