<footer class="footer">
  <div class="row align-items-center justify-content-xl-between">
    <div class="col-xl-6">
      <div class="copyright text-center text-xl-left text-muted">
        &copy; {{ test | date: "yyyy" }} <a href="https://www.secureidltd.com" class="font-weight-bold ml-1"
          target="_blank">
          <img class="sid-footer-logo" src="assets\img\siddiglog.png" alt="siddiglog">
        </a>
      </div>
    </div>
    <div class="col-xl-6">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <img class="footer-logo mr-2" alt="logo" src="../../../assets/img/INSIGHT SID 512.webp" />
          Insight {{version}}
          <!-- <a href="https://www.secureidltd.com?ref=ada-footer-admin-layout" class="nav-link" target="_blank">SecureID Limited</a> -->
        </li>
      </ul>
    </div>
  </div>
</footer>
