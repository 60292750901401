/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mat-confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./mat-confirm-dialog.component";
var styles_MatConfirmDialogComponent = [i0.styles];
var RenderType_MatConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatConfirmDialogComponent, data: {} });
export { RenderType_MatConfirmDialogComponent as RenderType_MatConfirmDialogComponent };
export function View_MatConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", " fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "content-span full-width"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "row pb-4 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col-5 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-danger btn-block"], ["id", "no-button"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["NO"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "col-5 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-primary btn-block"], ["id", "yes-button"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(16, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["YES"]))], function (_ck, _v) { var currVal_4 = false; _ck(_v, 10, 0, currVal_4); var currVal_8 = true; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).disabled || null); var currVal_2 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 10).ariaLabel || null); _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 15).disabled || null); var currVal_6 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 16).ariaLabel || null); _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_MatConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mat-confirm-dialog", [], null, null, null, View_MatConfirmDialogComponent_0, RenderType_MatConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.MatConfirmDialogComponent, [i7.MAT_DIALOG_DATA, i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MatConfirmDialogComponentNgFactory = i1.ɵccf("app-mat-confirm-dialog", i8.MatConfirmDialogComponent, View_MatConfirmDialogComponent_Host_0, {}, {}, []);
export { MatConfirmDialogComponentNgFactory as MatConfirmDialogComponentNgFactory };
