import { SwUpdate } from "@angular/service-worker";
import { Platform } from "@angular/cdk/platform";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@angular/cdk/platform";
var PwaService = /** @class */ (function () {
    function PwaService(swUpdate, platform) {
        // window.addEventListener("beforeinstallprompt", (event) => {
        //   this.promptEvent = event;
        // });
        var _this = this;
        this.swUpdate = swUpdate;
        this.platform = platform;
        this.allowUpdates = false;
        swUpdate.available.subscribe(function (event) {
            // notify user that update is available
            alert("New Update available! Reload app to see the latest juicy changes.");
            _this.allowUpdates = true;
            if (_this.allowUpdates) {
                window.location.reload();
            }
        });
    }
    PwaService.prototype.initPwaPrompt = function () {
        var _this = this;
        if (this.platform.ANDROID) {
            window.addEventListener("beforeinstallprompt", function (event) {
                event.preventDefault();
                // Stash the event so it can be triggered later.
                _this.deferredPrompt = event;
                _this.promptEvent = event;
                // this.openPromptComponent('android');
            });
        }
        else if (this.platform.IOS) {
            var isInStandaloneMode = "standalone" in window.navigator && window.navigator["standalone"];
            if (!isInStandaloneMode) {
                // this.openPromptComponent('ios');
            }
        }
        else {
            window.addEventListener("beforeinstallprompt", function (event) {
                event.preventDefault();
                _this.promptEvent = event;
                // this.openPromptComponent('android');
            });
        }
    };
    PwaService.ngInjectableDef = i0.defineInjectable({ factory: function PwaService_Factory() { return new PwaService(i0.inject(i1.SwUpdate), i0.inject(i2.Platform)); }, token: PwaService, providedIn: "root" });
    return PwaService;
}());
export { PwaService };
