/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/router";
import * as i5 from "./navbar.component";
import * as i6 from "../../pwa.service";
import * as i7 from "../../shared/helper.service";
import * as i8 from "../../shared/profile.service";
import * as i9 from "../../Auth/auth.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h6", [["class", "text-white h2 d-inline-block mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_NavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "breadcrumb-item text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.breadcrumbs == null) ? null : _co.breadcrumbs.parentBreadcrumb); _ck(_v, 2, 0, currVal_0); }); }
function View_NavbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["aria-current", "page"], ["class", "breadcrumb-item text-primary active"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.breadcrumbs == null) ? null : _co.breadcrumbs.childBreadcrumb); _ck(_v, 1, 0, currVal_0); }); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "nav", [["aria-label", "breadcrumb"], ["class", "d-none d-md-inline-block ml-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "ol", [["class", "breadcrumb breadcrumb-links "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "li", [["class", "breadcrumb-item text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-home"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.breadcrumbs == null) ? null : _co.breadcrumbs.parentBreadcrumb); _ck(_v, 7, 0, currVal_0); var currVal_1 = ((_co.breadcrumbs == null) ? null : _co.breadcrumbs.childBreadcrumb); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 54, "nav", [["class", "navbar navbar-top navbar-expand-md navbar-primary"], ["id", "navbar-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 53, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "d-inline-block align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 46, "div", [["class", "ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 45, "ul", [["class", "navbar-nav align-items-center d-none d-md-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 44, "li", [["class", "nav-item"], ["ngbDropdown", ""], ["placement", "bottom-right"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(13, 212992, null, 2, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(16, 0, null, null, 12, "a", [["aria-haspopup", "true"], ["class", "nav-link btn btn-light p-2 dropdown-toggle"], ["ngbDropdownToggle", ""], ["role", "button"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i3.ɵr, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i3.ɵq, null, [i3.ɵr]), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "media align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 8, "div", [["class", "media-body ml-2 d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 7, "span", [["class", "mb-0 text-sm text-white font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(0, i2.SlicePipe, []), i1.ɵppd(24, 1), (_l()(), i1.ɵeld(25, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(26, null, [" ", " "])), i1.ɵpid(0, i2.SlicePipe, []), i1.ɵppd(28, 1), (_l()(), i1.ɵeld(29, 0, null, null, 27, "div", [["class", "dropdown-menu-arrow dropdown-menu-right"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(30, 16384, [[1, 4]], 0, i3.ɵp, [i3.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(31, 0, null, null, 2, "div", [["class", " dropdown-header noti-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "h6", [["class", "text-overflow m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome!"])), (_l()(), i1.ɵeld(34, 0, null, null, 8, "a", [["class", "dropdown-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 35).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(35, 671744, [[4, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(36, 1), i1.ɵdid(37, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(40, 0, null, null, 0, "i", [["class", "ni ni-single-02"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My profile"])), (_l()(), i1.ɵeld(43, 0, null, null, 8, "a", [["class", "dropdown-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 44).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(44, 671744, [[6, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(45, 1), i1.ɵdid(46, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(49, 0, null, null, 0, "i", [["class", "ni ni-settings-gear-65"]], null, null, null, null, null)), (_l()(), i1.ɵeld(50, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change Password"])), (_l()(), i1.ɵeld(52, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 3, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(54, 0, null, null, 0, "i", [["class", "ni ni-user-run"]], null, null, null, null, null)), (_l()(), i1.ɵeld(55, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); var currVal_1 = (((_co.breadcrumbs == null) ? null : _co.breadcrumbs.parentBreadcrumb) || ((_co.breadcrumbs == null) ? null : _co.breadcrumbs.childBreadcrumb)); _ck(_v, 9, 0, currVal_1); var currVal_3 = "bottom-right"; _ck(_v, 13, 0, currVal_3); var currVal_12 = _ck(_v, 36, 0, "/user-profile"); _ck(_v, 35, 0, currVal_12); var currVal_13 = "active"; _ck(_v, 37, 0, currVal_13); var currVal_16 = _ck(_v, 45, 0, "/forgot-password"); _ck(_v, 44, 0, currVal_16); var currVal_17 = "active"; _ck(_v, 46, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 13).isOpen(); _ck(_v, 12, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 17).dropdown.isOpen(); _ck(_v, 16, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 22, 0, _ck(_v, 24, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform(((_co.profile == null) ? null : _co.profile.firstname), "0", "1")))); _ck(_v, 22, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 26, 0, _ck(_v, 28, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform(((_co.profile == null) ? null : _co.profile.lastname), "0", "1")))); _ck(_v, 26, 0, currVal_6); var currVal_7 = true; var currVal_8 = i1.ɵnov(_v, 30).dropdown.isOpen(); var currVal_9 = i1.ɵnov(_v, 30).placement; _ck(_v, 29, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵnov(_v, 35).target; var currVal_11 = i1.ɵnov(_v, 35).href; _ck(_v, 34, 0, currVal_10, currVal_11); var currVal_14 = i1.ɵnov(_v, 44).target; var currVal_15 = i1.ɵnov(_v, 44).href; _ck(_v, 43, 0, currVal_14, currVal_15); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i5.NavbarComponent, [i6.PwaService, i2.Location, i4.ActivatedRoute, i1.ElementRef, i4.Router, i7.HelperService, i8.ProfileService, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i5.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
