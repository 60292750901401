var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SortingService } from "src/app/shared/sorting.service";
import { ViewPackageDialogComponent } from "src/app/sorting-list/view-package-dialog/view-package-dialog.component";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import { TrackAgentDialogComponent } from "src/app/track-agent-dialog/track-agent-dialog.component";
import { Router } from "@angular/router";
var TrackPackageComponent = /** @class */ (function () {
    function TrackPackageComponent(fb, packageDialog, sortService, router // private route: ActivatedRoute
    ) {
        this.fb = fb;
        this.packageDialog = packageDialog;
        this.sortService = sortService;
        this.router = router;
        this.adminTrack = false;
        this.packageSub = new Subscription();
    }
    TrackPackageComponent.prototype.ngOnInit = function () {
        if (this.router.url == "/track-package") {
            this.adminTrack = true;
        }
        // this.checkMode();
        this.form = this.fb.group({
            // id: [""],
            track_id: ["", [Validators.required]],
        });
    };
    TrackPackageComponent.prototype.ngOnDestroy = function () {
        this.packageSub.unsubscribe();
    };
    // checkMode() {
    //   this.route.paramMap.subscribe((paramMap: ParamMap) => {
    //     if (paramMap.has("id")) {
    //       let id = paramMap.get("id");
    //       this.mode = "edit";
    //       this.title = "Edit Department";
    //       // this.earningForm.patchValue({ id: id });
    //       this.sortService.getDepartmentByID(id);
    //       this.sortService.getDepartmentUpdate().subscribe((department) => {
    //         // this.logger.log(department);
    //         // this.department = department;
    //         // // console.log(this.earning);
    //         this.departmentForm.setValue({
    //           id: department.id,
    //           name: department.name,
    //         });
    //       });
    //       // this.logger.log(this.earningForm.value);
    //     } else {
    //       this.mode = "create";
    //       this.title = "New Department";
    //     }
    //   });
    // }
    TrackPackageComponent.prototype.onSave = function () {
        if (this.form.invalid) {
            return;
        }
        var track_id = this.form.value.track_id;
        var res = track_id.slice(0, 3);
        if (res == "ref") {
            this.viewSIDPackage(track_id);
        }
        else {
            this.viewExtPackage(track_id);
        }
    };
    TrackPackageComponent.prototype.viewExtPackage = function (track_id) {
        var _this = this;
        this.sortService.getDetailsByTrackingID(track_id);
        this.packageSub = this.sortService.getPackageUpdate().subscribe(function (res) {
            var dialogRef = _this.packageDialog.open(ViewPackageDialogComponent, {
                width: "80%",
                panelClass: "confirm-dialog-container",
                disableClose: true,
                // position: { top: "10px" },
                data: {
                    job: res,
                },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                _this.packageSub.unsubscribe();
                // // console.log("The dialog was closed");
                // this.animal = result;
            });
        });
    };
    TrackPackageComponent.prototype.viewSIDPackage = function (item) {
        var _this = this;
        this.sortService.getSIDPackageDetailsByRef(item);
        this.packageSub = this.sortService.getPackageUpdate().subscribe(function (res) {
            var dialogRef = _this.packageDialog.open(TrackAgentDialogComponent, {
                width: "80%",
                panelClass: "confirm-dialog-container",
                disableClose: true,
                // position: { top: "10px" },
                data: __assign({}, res),
            });
            dialogRef.afterClosed().subscribe(function (result) {
                _this.packageSub.unsubscribe();
                // // console.log("The dialog was closed");
                // this.animal = result;
            });
        });
    };
    return TrackPackageComponent;
}());
export { TrackPackageComponent };
