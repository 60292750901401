import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table/table.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LazyLoadDirective } from "./img-lazyload.directive";
import { DarkTableComponent } from "./dark-table/dark-table.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularMaterialModule } from "../angular-material/angular-material.module";
import { MatConfirmDialogComponent } from "./mat-confirm-dialog/mat-confirm-dialog.component";
import { ErrorComponent } from "./error/error.component";
import { TableFunctionsComponent } from "./table-functions/table-functions.component";
import { ToastrModule } from "ngx-toastr";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxDropzoneModule } from "ngx-dropzone";
import { PaginatorComponent } from "./paginator/paginator.component";
import { CardStatsComponent } from "./card-stats/card-stats.component";
import { PhonePipe } from "./phone.pipe";
import { NoDataComponent } from "./no-data/no-data.component";
import { ImgPipe } from "./img.pipe";

@NgModule({
  declarations: [
    TableComponent,
    LazyLoadDirective,
    DarkTableComponent,
    MatConfirmDialogComponent,
    ErrorComponent,
    TableFunctionsComponent,
    PaginatorComponent,
    CardStatsComponent,
    PhonePipe,
    NoDataComponent,
    ImgPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    AngularMaterialModule,
    CarouselModule,
    NgxDropzoneModule,
    GooglePlaceModule,
  ],
  exports: [
    PaginatorComponent,
    TableFunctionsComponent,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    TableComponent,
    DarkTableComponent,
    LazyLoadDirective,
    ToastrModule,
    CarouselModule,
    NgxDropzoneModule,
    CardStatsComponent,
    PhonePipe,
    NoDataComponent,
    ImgPipe,
  ],
})
export class SharedModule {}
