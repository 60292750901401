import "hammerjs";
/*!

=========================================================
* Argon Dashboard Angular - v1.0.1
=========================================================

* Product Page: https://www.secureidltd.com/product/argon-dashboard-angular
* Copyright 2019 SecureID Limited (https://www.secureidltd.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-angular/blob/master/LICENSE.md)

* Coded by SecureID Limited

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    // service worker modification
    // if ("serviceWorker" in navigator && environment.production) {
    //   navigator.serviceWorker.register("/ngsw-worker.js");
    // }
    // Ensure Angular destroys itself on hot reloads.
    if (window["ngRef"]) {
      window["ngRef"].destroy();
    }
    window["ngRef"] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));
