import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./auth.guard";
import { RouteGuard } from "./route.guard";
import { Page404Component } from "./page404/page404.component";
import { Page500Component } from "./page500/page500.component";
import { TrackPackageComponent } from "./courier/track-package/track-package.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./landing/landing.module#LandingModule",
  },
  // {
  //   path: "",
  //   component: AuthLayoutComponent,
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: "./landing/landing.module#LandingModule",
  //     },
  //   ],
  // },
  {
    path: "track-landing",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        component: TrackPackageComponent,
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./Auth/auth.module#AuthModule",
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule",
      },
    ],
  },
  {
    path: "404",
    component: Page404Component,
  },
  {
    path: "500",
    component: Page500Component,
  },
  // {
  //   path: "**",
  //   redirectTo: "404",
  // },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
