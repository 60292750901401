import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { NotificationService } from "./notification.service";
import { ErrorService } from "./error/error.service";
import { LogService } from "./log.service";
import { AuthService } from "../Auth/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // // console.log(error);
        // console.log(error.error);
        let errorMessage = error.statusText;
        // let errorMessage = 'An unknown error occurred!';
        // if (error.error.code == "token_not_valid") {
        //   // refresh token
        //   this.authService.onRefreshToken();
        // }

        if (error.status == 401) {
          this.authService.logout();
          this.notificationService.error(
            `We couldn't verify you, please provide login info`
          );
        }
        // this.notification.warn(errorMessage);
        // this.logger.error(errorMessage);
        // this.dialog.open(ErrorComponent, { data: { message: errorMessage } });
        // this.errorService.throwError(errorMessage);
        return throwError(error);
      })
    );
  }
}
