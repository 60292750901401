export const ERROR_MESSAGE_DICTIONARY = {
  title: 'error messages',
  e451: '',

  // 500 Series
  e500: 'Service Not Reachable',
  e501: '',
  e502: '',
  e503: '',
  e504: 'Service Unavailable',
  e505: '',
  e506: '',
  e507: '',
  e508: '',
  e509: '',
  e510: '',
  e511: '',

  // 400 Series
  e400: 'Part of your request was submitted incorrectly, please try it again.',
  e401: 'Unauthorized. Please Contact Administrator',
  e402: '',
  e403: 'Insufficient Permissions, Please contact Administrator.',
  e404: 'Not Found.',
  e405: '',
  e406: '',
  e407: '',
  e408: '',
  e409: '',
  e410: '',
  e411: '',
  e412: '',
  e413: 'The media type you entered is too large. Please use media of a smaller size.',
  e414: '',
  e415: 'The media type you entered is not allowed. Please contact administrator.',
  e416: '',
  e417: '',
  // e418: '',
  e419: '',
  e421: '',
  e422: '',
  e423: '',
  e424: '',
  e426: '',
  e428: '',
  e429: '',
  e431: '',

  loginE400: 'Part of your request was submitted incorrectly, please try it again.',
  loginE401: 'Incorrect Username or Password',
  loginE402: '',
  loginE403: 'Insufficient Permissions, Please contact Administrator.',
  loginE404: 'Not Found.',
  loginE405: '',
  loginE406: '',
  loginE407: '',
  loginE408: '',
  loginE409: '',
  loginE410: '',
  loginE411: '',
  loginE412: '',
  loginE413: '',
  loginE414: '',
  loginE415: 'The media type you entered is not allowed. Please contact administrator.',
  loginE416: '',
  loginE417: '',
  // e418: '',
  loginE419: '',
  loginE421: '',
  loginE422: '',
  loginE423: '',
  loginE424: '',
  loginE426: '',
  loginE428: '',
  loginE429: '',
  loginE431: '',
  loginE451: '',

  // 400 Series
  passwordE400: 'Incorrect Request, Please contact Administrator.',
  passwordE401: 'Unauthorized. Please Contact Administrator',
  passwordE402: '',
  passwordE403: 'Insufficient Permissions, Please contact Administrator.',
  passwordE404: 'Password resource not Found.',
  passwordE405: 'Incorrect Request Method, Please contact Administrator.',
  passwordE406: '',
  passwordE407: '',
  passwordE408: '',
  passwordE409: '',
  passwordE410: '',
  passwordE411: '',
  passwordE412: '',
  passwordE413: '',
  passwordE414: '',
  passwordE415: 'The media type you entered is not allowed. Please contact administrator.',
  passwordE416: '',
  passwordE417: '',
  // 418: '',
  passwordE419: '',
  passwordE421: '',
  passwordE422: '',
  passwordE423: '',
  passwordE424: '',
  passwordE426: '',
  passwordE428: '',
  passwordE429: '',
  passwordE431: '',
  passwordE451: '',

  // 400 Series
  cardE400: 'A valid Card ID is required.',
  cardE401: 'Unauthorized. Please Contact Administrator',
  cardE402: '',
  cardE403: 'Insufficient Permissions, Please contact Administrator.',
  cardE404: 'Card record not found.',
  cardE405: 'Incorrect Request Method, Please contact Administrator.',
  cardE406: '',
  cardE407: '',
  cardE408: '',
  cardE409: '',
  cardE410: '',
  cardE411: '',
  cardE412: '',
  cardE413: '',
  cardE414: '',
  cardE415: 'The media type you entered is not allowed. Please contact administrator.',
  cardE416: '',
  cardE417: '',
  // 418: '',
  cardE419: '',
  cardE421: '',
  cardE422: '',
  cardE423: '',
  cardE424: '',
  cardE426: '',
  cardE428: '',
  cardE429: '',
  cardE431: '',
  cardE451: '',

  // 400 Series
  createUserE400: 'User already exists or email already used.',
  createUserE401: 'Unauthorized. Please Contact Administrator',
  createUserE402: '',
  createUserE403: 'Insufficient Permissions, Please contact Administrator.',
  createUserE404: 'User resource not found.',
  createUserE405: 'Incorrect Request Method, Please contact Administrator.',
  createUserE406: '',
  createUserE407: '',
  createUserE408: '',
  createUserE409: '',
  createUserE410: '',
  createUserE411: '',
  createUserE412: '',
  createUserE413: '',
  createUserE414: '',
  createUserE415: 'The media type you entered is not allowed. Please contact administrator.',
  createUserE416: '',
  createUserE417: '',
  // 418: '',
  createUserE419: '',
  createUserE421: '',
  createUserE422: '',
  createUserE423: '',
  createUserE424: '',
  createUserE426: '',
  createUserE428: '',
  createUserE429: '',
  createUserE431: '',
  createUserE451: '',

  // 500 Series
  // passwordE500: 'Service Not Reachable',
  // passwordE501: '',
  // passwordE502: '',
  // passwordE503: '',
  // passwordE504: 'Service Unavailable',
  // passwordE505: '',
  // passwordE506: '',
  // passwordE507: '',
  // passwordE508: '',
  // passwordE509: '',
  // passwordE510: '',
  // passwordE511: '',

};
