// import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var NotificationService = /** @class */ (function () {
    function NotificationService(
    // public snackBar: MatSnackBar,
    toastr) {
        this.toastr = toastr;
        // private config: MatSnackBarConfig = {
        //   duration: 10000,
        //   horizontalPosition: "right",
        //   verticalPosition: "top",
        // };
        this.options = {
            timeOut: 3000,
            closeButton: true,
        };
    }
    NotificationService.prototype.success = function (msg) {
        this.toastr.success("Success!", msg, this.options);
        // this.config["panelClass"] = ["notification", "success"];
        // this.snackBar.open(msg, "", this.config);
    };
    NotificationService.prototype.info = function (msg) {
        this.toastr.info("Info!", msg, this.options);
        // this.config["panelClass"] = ["notification", "info"];
        // this.snackBar.open(msg, "", this.config);
    };
    NotificationService.prototype.warn = function (msg) {
        this.toastr.warning("warning!", msg, this.options);
        // this.config["panelClass"] = ["notification", "warn"];
        // this.snackBar.open(msg, "", this.config);
    };
    NotificationService.prototype.error = function (msg) {
        this.toastr.error("An error occured!", msg, this.options);
        // this.config["panelClass"] = ["notification", "error"];
        // this.snackBar.open(msg, "", this.config);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ToastrService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
