import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { ERROR_MESSAGE_DICTIONARY } from "./error-message";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { HelperService } from "./helper.service";
import { LogService } from "./log.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private profiles: any[] = [];
  private profile;
  private profilesUpdated = new Subject<any[]>();
  private profileUpdate = new Subject<any[]>();
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    private helpService: HelperService,
    private logger: LogService,
    private router: Router
  ) {}

  getProfileById(id) {
    // console.log("here now 1");
    const userGroup = this.helpService.getUserGroup();
    // console.log(userGroup);
    switch (userGroup) {
      case 1:
        // code block
        this.getSidProfileByID(id);
        break;
      case 2:
        // code block
        this.getSidProfileByID(id);
        break;
      case 3:
        // code block
        this.getSidProfileByID(id);
        break;
      case 4:
        // code block
        this.getSidProfileByID(id);
        break;
      case 5:
        // code block
        this.getUserProfileByID(id);
        break;
      case 6:
        // code block
        this.getUserProfileByID(id);
        break;
      case 7:
        // code block
        this.getUserProfileByID(id);
        break;
      default:
        // code block
        this.getUserProfileByID(id);
        break;
    }
  }

  getUserProfileByID(user_id) {
    // const profile = this.profiles.filter(theProfile => theProfile.user === id);
    // return profile[0];
    // console.log("here now 2");

    return this.http
      .post<any>(`${this.apiUrl}app/get/user/profile/`, { user_id })
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.profile = data[0];
            this.saveProfileData(
              this.profile.firstname,
              this.profile.lastname,
              this.profile.username,
              this.profile.phone
              // this.profile.bank_code
            );
            this.saveBankDetails(
              this.profile.bank_code,
              this.profile.shipper_id,
              this.profile.assigned_courier
            );
            this.profileUpdate.next({ ...this.profile });
          }

          // console.log(data);
        },
        (err) => {
          return;
        }
      );
  }

  getSidProfileByID(user_id) {
    // const profile = this.profiles.filter(theProfile => theProfile.user === id);
    // return profile[0];

    this.http
      .post<any>(`${this.apiUrl}app/get/sid_user/profile/`, { user_id })
      .subscribe(
        (data) => {
          // this.logger.log(data);
          // console.log(data);
          this.profile = data[0];
          this.saveProfileData(
            this.profile.firstname,
            this.profile.lastname,
            this.profile.username,
            this.profile.phone
            // null
          );
          // // console.log(this.profile);
          this.profileUpdate.next({ ...this.profile });
        },
        (err) => {
          return;
        }
      );
  }

  getProfileListener() {
    return this.profileUpdate.asObservable();
  }

  private async saveProfileData(
    firstname: string,
    lastname: string,
    username: string,
    phone: string
    // bank_code: string
  ) {
    // console.log("here now 3");
    await localStorage.setItem("firstname", JSON.stringify(firstname));
    await localStorage.setItem("lastname", JSON.stringify(lastname));
    await localStorage.setItem("username", JSON.stringify(username));
    await localStorage.setItem("phone", JSON.stringify(phone));
    // await localStorage.setItem("bank_code", JSON.stringify(bank_code));
  }

  private saveBankDetails(bank_code, shipper_id, assigned_courier) {
    // console.log("here now 3");
    if (assigned_courier) {
      localStorage.setItem(
        "assigned_courier",
        JSON.stringify(assigned_courier)
      );
    }
    if (bank_code) {
      localStorage.setItem("bank_code", JSON.stringify(bank_code));
    }
    if (shipper_id) {
      localStorage.setItem("shipper_id", JSON.stringify(shipper_id));
    }
  }

  // getOtherProfileById(id) {
  //   this.http.get<any>(`${this.apiUrl}loyalty/profile/${id}`).subscribe(
  //     (data) => {
  //       this.profile = data;
  //       this.profileUpdate.next({ ...this.profile });
  //       // return data;
  //     },
  //     (err) => {
  //       return;
  //     }
  //   );
  // }

  // getAllProfiles() {
  //   // get Earnings List
  //   this.http.get<any[]>(`${this.apiUrl}loyalty/profile/`).subscribe(
  //     (res: any[]) => {
  //       this.logger.log(res);
  //       this.profiles = res;
  //       this.profilesUpdated.next([...this.profiles]);
  //     },
  //     (err: any) => {
  //       switch (err.status) {
  //         case 400: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
  //           break;
  //         }
  //         case 401: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
  //           break;
  //         }
  //         case 403: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
  //           break;
  //         }
  //         case 404: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
  //           break;
  //         }
  //         case 415: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
  //           break;
  //         }
  //         case 500: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
  //           break;
  //         }
  //         case 503: {
  //           this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
  //           break;
  //         }
  //       }
  //       // this.isUploading = false;
  //     }
  //   );
  // }

  changeDp(image) {
    const id = this.helpService.getUser();
    this.logger.log(image);
    // // console.log(form);
    this.http.patch(`${this.apiUrl}app/users/${id}/`, { image }).subscribe(
      (data) => {
        this.logger.log(data);
        this.notification.success(":: Profile Updated Successfully");
        this.router.navigate(["/user-profile"]);
        window.location.reload();
      },
      (err: any) => {
        window.location.reload();
        // console.log(err);
        // console.log(err.error);
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }

  updateProfile(form) {
    const id = this.helpService.getUser();
    this.logger.log(form);
    // // console.log(form);
    this.http.patch(`${this.apiUrl}app/users/${id}/`, form).subscribe(
      (data) => {
        this.logger.log(data);
        this.notification.success(":: Profile Updated Successfully");
        this.router.navigate(["/user-profile"]);
      },
      (err: any) => {
        this.logger.error(err);
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }
}
